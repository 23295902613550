<template>

<div class="delete" :class="{'is-loading': isLoading}">

	<app-icon icon="warning" class="delete-icon" />

	<div class="delete-title">Unsaved changes</div>

	<div class="delete-text">Are you sure you wish to leave without saving your changes?</div>

	<div class="delete-buttons">

		<div class="delete-buttons-item is-danger" v-on:click="onConfirmClick">Yes</div>
		<div class="delete-buttons-item" v-on:click="onCancelClick">No</div>

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			isLoading: false
		}

	},

	computed: {

		noun: function() {

			return this.$route.meta.noun

		},

		title: function() {

			return 'title'

		}

	},

	methods: {

		onConfirmClick: function() {
			
			this.$router.push({
				path: this.$route.query.redirect
			})
			
		},

		onCancelClick: function() {
			
			this.$router.push({
				name: this.$route.meta.back,
				params: {
					id: this.$route.params.id
				}
			})

		}

	}

}

</script>

<style scoped>

.delete {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #ffbe7a;
}

.delete.is-loading {
	pointer-events: none;
}

.delete-icon {
	font-size: 96px;
}

.delete-title {
	font-size: 24px;
	font-weight: 400;
	padding: 20px 0px;
}

.delete-text {
	font-size: 16px;
	line-height: 20px;
	padding: 0px 100px;
	text-align: center;
	color: #333;
	font-weight: 300;
}

.delete-buttons {
	margin-top: 20px;
	display: flex;
	justify-content: center;
}

.delete-buttons-item {
	width: 150px;
	height: 40px;
	border: 2px solid #ccc;
	line-height: 36px;
	text-align: center;
	border-radius: 16px;
	color: #333;
	margin: 0px 10px;
	font-size: 16px;
	cursor: pointer;
}

.delete-buttons-item.is-danger {
	color: #fff;
	font-weight: 400;
	border-color: #ffbe7a;
	background-color: #ffbe7a;
}

</style>
